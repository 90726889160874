export const searchList = [
  // {
  //   label: '',
  //   model: 'exchangeType',
  //   type: 'select',
  //   size: 'small',
  //   clearable: false,
  //   options: [
  //     {
  //       value: 'HK',
  //       label: '港股'
  //     },
  //     {
  //       value: 'US',
  //       label: '美股'
  //     },
  //     {
  //       value: 'A',
  //       label: 'A股'
  //     }
  //   ],
  //   placeholder: ''
  // },
  {
    label: '',
    model: 'statementType',
    type: 'select',
    size: 'small',
    clearable: false,
    options: [
      {
        value: 'ALL',
        label: '全部结单'
      },
      {
        value: 'D',
        label: '日结单'
      },
      {
        value: 'M',
        label: '月结单'
      }
    ],
    placeholder: ''
  },
  {
    label: '',
    model: 'dataType',
    type: 'select',
    size: 'small',
    clearable: false,
    options: [
      {
        value: 0,
        label: '全部日期'
      },
      {
        value: 1,
        label: '自定义日期'
      }
    ],
    placeholder: ''
  },
  {
    label: '',
    model: 'date',
    type: 'date',
    size: 'small',
    startPlaceholder: '开始日期',
    endPlaceholder: '结束日期',
    placeholder: ''
  }
]
export const searchListNoDate = [
  // {
  //   label: '',
  //   model: 'exchangeType',
  //   type: 'select',
  //   size: 'small',
  //   clearable: false,
  //   options: [
  //     {
  //       value: 'HK',
  //       label: '港股'
  //     },
  //     {
  //       value: 'US',
  //       label: '美股'
  //     },
  //     {
  //       value: 'A',
  //       label: 'A股'
  //     }
  //   ],
  //   placeholder: ''
  // },
  {
    label: '',
    model: 'statementType',
    type: 'select',
    size: 'small',
    clearable: false,
    options: [
      {
        value: 'ALL',
        label: '全部结单'
      },
      {
        value: 'D',
        label: '日结单'
      },
      {
        value: 'M',
        label: '月结单'
      }
    ],
    placeholder: ''
  },
  {
    label: '',
    model: 'dataType',
    type: 'select',
    size: 'small',
    clearable: false,
    options: [
      {
        value: 0,
        label: '全部日期'
      },
      {
        value: 1,
        label: '自定义日期'
      }
    ],
    placeholder: ''
  }
]

export const tableData = [
  {
    title: '2022年3月',
    selectStatus: false,
    list: [
      {
        title: '月结单',
        selectStatus: false,
        statementType: 'M',
        statementId: '4153',
        month: 3,
        statementLink:
          'https://filescfcdn.fwdev.top/public/ayers_report/daily/20220210/100007.001_20220210_D.pdf'
      },
      {
        title: '3/15-日结单',
        selectStatus: false,
        statementType: 'D',
        statementId: '4154',
        month: 3,
        day: 15,
        statementLink:
          'https://filescfcdn.fwdev.top/public/ayers_report/daily/20220210/100007.001_20220210_D.pdf'
      },
      {
        title: '3/14-日结单',
        selectStatus: false,
        statementType: 'D',
        statementId: '4155',
        month: 3,
        day: 14,
        statementLink:
          'https://filescfcdn.fwdev.top/public/ayers_report/daily/20220210/100007.001_20220210_D.pdf'
      },
      {
        title: '3/13-日结单',
        selectStatus: false,
        statementType: 'D',
        statementId: '4156',
        month: 3,
        day: 13,
        statementLink:
          'https://filescfcdn.fwdev.top/public/ayers_report/daily/20220210/100007.001_20220210_D.pdf'
      }
    ]
  }
]
