import * as gql from '@/graphql'
import { baseClient } from '@/apollo-client'
// 获取列表数据
export const getStatementsPageList = <T, U>(variables: any, fetchPolicy: U) =>
  baseClient.query({
    query: gql.statementsPageList,
    variables,
    ...fetchPolicy
  })
// 发送到邮箱
export const sendToEmail = <T, U>(variables: any, fetchPolicy: U) =>
  baseClient.mutate({
    mutation: gql.sendEmail,
    variables,
    ...fetchPolicy
  })
