import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/images/statement/select.png'
import _imports_1 from '@/assets/images/statement/select1.png'
import _imports_2 from '@/assets/images/statement/day.png'
import _imports_3 from '@/assets/images/statement/month.png'
import _imports_4 from '@/assets/images/home/noData.png'
import _imports_5 from '@/assets/images/statement/send.png'
import _imports_6 from '@/assets/images/statement/disableSend.png'
import _imports_7 from '@/assets/images/home/info.png'


const _hoisted_1 = { class: "statement" }
const _hoisted_2 = { class: "statement-con" }
const _hoisted_3 = { class: "statement-search" }
const _hoisted_4 = { class: "statement-main" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = ["infinite-scroll-disabled"]
const _hoisted_7 = { class: "list-title" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = ["onClick"]
const _hoisted_10 = ["onClick"]
const _hoisted_11 = ["onClick"]
const _hoisted_12 = {
  key: 2,
  src: _imports_2,
  alt: ""
}
const _hoisted_13 = {
  key: 3,
  src: _imports_3,
  alt: ""
}
const _hoisted_14 = { class: "check-pdf" }
const _hoisted_15 = ["href"]
const _hoisted_16 = {
  key: 1,
  class: "no-data"
}
const _hoisted_17 = { class: "statement-foot" }
const _hoisted_18 = {
  key: 0,
  src: _imports_5,
  alt: ""
}
const _hoisted_19 = {
  key: 1,
  src: _imports_6,
  alt: ""
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FargoSearch = _resolveComponent("FargoSearch")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!
  const _component_tips_dialog = _resolveComponent("tips-dialog")!
  const _directive_infinite_scroll = _resolveDirective("infinite-scroll")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_FargoSearch, {
          "search-list": _ctx.searchList,
          searchFormData: _ctx.searchFormData,
          "onUpdate:searchFormData": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchFormData) = $event))
        }, null, 8, ["search-list", "searchFormData"])
      ]),
      _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_4, [
        _createVNode(_component_el_scrollbar, {
          style: _normalizeStyle({ '--height': _ctx.height }),
          class: "scroll-wrapper",
          ref: "list"
        }, {
          default: _withCtx(() => [
            (_ctx.tableData.length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableData, (item, index) => {
                    return _withDirectives((_openBlock(), _createElementBlock("div", {
                      class: "main-list",
                      key: index,
                      "infinite-scroll-disabled": _ctx.loadLoading,
                      "infinite-scroll-distance": "15000",
                      "infinite-scroll-delay": "50",
                      "infinite-scroll-immediate-check": "false",
                      "infinite-scroll-immediate": "false"
                    }, [
                      _createElementVNode("div", _hoisted_7, [
                        (!item.selectStatus)
                          ? (_openBlock(), _createElementBlock("img", {
                              key: 0,
                              onClick: ($event: any) => (_ctx.monthAll(item, true)),
                              class: "select-img",
                              src: _imports_0,
                              alt: ""
                            }, null, 8, _hoisted_8))
                          : _createCommentVNode("", true),
                        (item.selectStatus)
                          ? (_openBlock(), _createElementBlock("img", {
                              key: 1,
                              onClick: ($event: any) => (_ctx.monthAll(item, false)),
                              class: "select-img",
                              src: _imports_1,
                              alt: ""
                            }, null, 8, _hoisted_9))
                          : _createCommentVNode("", true),
                        _createElementVNode("span", null, _toDisplayString(item.title), 1)
                      ]),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.list, (listItem, index1) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: "list-con",
                          key: index1
                        }, [
                          _createElementVNode("div", null, [
                            (!listItem.selectStatus)
                              ? (_openBlock(), _createElementBlock("img", {
                                  key: 0,
                                  onClick: ($event: any) => (_ctx.dayAll(listItem, item, true)),
                                  class: "select-img",
                                  src: _imports_0,
                                  alt: ""
                                }, null, 8, _hoisted_10))
                              : _createCommentVNode("", true),
                            (listItem.selectStatus)
                              ? (_openBlock(), _createElementBlock("img", {
                                  key: 1,
                                  onClick: ($event: any) => (_ctx.dayAll(listItem, item, false)),
                                  class: "select-img",
                                  src: _imports_1,
                                  alt: ""
                                }, null, 8, _hoisted_11))
                              : _createCommentVNode("", true),
                            (listItem.statementType === 'D')
                              ? (_openBlock(), _createElementBlock("img", _hoisted_12))
                              : _createCommentVNode("", true),
                            (listItem.statementType === 'M')
                              ? (_openBlock(), _createElementBlock("img", _hoisted_13))
                              : _createCommentVNode("", true),
                            _createElementVNode("span", null, _toDisplayString(_ctx.showDayMonth(listItem)), 1)
                          ]),
                          _createElementVNode("span", _hoisted_14, [
                            _createElementVNode("a", {
                              href: listItem.statementLink,
                              target: "_blank"
                            }, "查看PDF", 8, _hoisted_15)
                          ])
                        ]))
                      }), 128))
                    ], 8, _hoisted_6)), [
                      [_directive_infinite_scroll, _ctx.load]
                    ])
                  }), 128))
                ]))
              : _createCommentVNode("", true),
            (!_ctx.tableData.length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_16, _cache[4] || (_cache[4] = [
                  _createElementVNode("img", {
                    src: _imports_4,
                    alt: "",
                    style: {"width":"148px","height":"100px","margin-bottom":"-30px"}
                  }, null, -1),
                  _createElementVNode("div", null, "暂无数据", -1)
                ])))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["style"])
      ])), [
        [_directive_loading, _ctx.mainLoading]
      ]),
      _createElementVNode("div", _hoisted_17, [
        _createElementVNode("span", {
          class: _normalizeClass({ 'disable-style': _ctx.selectCount.count < 1 })
        }, "已选中 (" + _toDisplayString(_ctx.selectCount.count) + ")", 3),
        _createElementVNode("div", {
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.sendEmail && _ctx.sendEmail(...args))),
          class: _normalizeClass({ 'disable-style': _ctx.selectCount.count < 1 })
        }, [
          (_ctx.selectCount.count > 0)
            ? (_openBlock(), _createElementBlock("img", _hoisted_18))
            : _createCommentVNode("", true),
          (_ctx.selectCount.count < 1)
            ? (_openBlock(), _createElementBlock("img", _hoisted_19))
            : _createCommentVNode("", true),
          _cache[5] || (_cache[5] = _createElementVNode("span", null, "发送到我邮箱", -1))
        ], 2),
        _createElementVNode("div", {
          class: "foot-info",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.sendPdfInfo && _ctx.sendPdfInfo(...args)))
        }, _cache[6] || (_cache[6] = [
          _createElementVNode("img", {
            src: _imports_7,
            alt: ""
          }, null, -1),
          _createElementVNode("span", null, "温馨提示", -1)
        ]))
      ])
    ]),
    _createVNode(_component_tips_dialog, {
      tipsDialog: _ctx.pwdDialog,
      "onUpdate:tipsDialog": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.pwdDialog) = $event)),
      title: "密码提示"
    }, {
      content: _withCtx(() => _cache[7] || (_cache[7] = [
        _createElementVNode("div", { class: "tips-content" }, [
          _createElementVNode("div", null, " 密码示例：香港身份证号码：A123456(B) → 请使用23456B, 中国居民身份证号码：123456789099887554 → 请使用887554, 护照号码：YZ0012347 → 请使用012347, 香港商业登记号码：99123456 → 请使用 123456, 公司注册证号码：CI1234BX → 请使用 1234BX ")
        ], -1)
      ])),
      _: 1
    }, 8, ["tipsDialog"])
  ]))
}