
import { searchList, searchListNoDate, tableData } from './data'
import { ElMessage, ElMessageBox } from 'element-plus'
import { computed, defineComponent, reactive, toRefs, ref, watch } from 'vue'
import storage, { StorageType } from '@/utils/storage'
import _ from 'lodash'
import { SearchFormData } from './statement'
import { getStatementsPageList, sendToEmail } from '@/apis/statement'
export default defineComponent({
  name: 'Statement',
  setup () {
    const list: any = ref(null)
    const state = reactive({
      searchList: searchListNoDate as any,
      tableData: [] as any,
      searchFormData: {
        exchangeType: 'ALL',
        statementType: 'ALL',
        dataType: 0,
        startDate: '',
        endDate: ''
      } as SearchFormData,
      height: window.innerHeight - 300 + 'px', // 初始化高度
      page: 1,
      pageSize: 20,
      total: 0,
      fundAccount: storage.rcGetItem(StorageType.local, 'userInfo'), // 资金账户
      email: '', // 用户邮箱
      sendLoading: false, // 发送loading
      mainLoading: true, // 表格loading
      loadLoading: false, // 滚动加载loading
      pwdDialog: false
    })
    const statementsParams = ref({
      filter: {
        exchangeType: 'ALL',
        statementType: 'ALL',
        fundAccount: state.fundAccount,
        page: state.page,
        pageSize: state.pageSize
      }
    }) as any
    const filArr = (arr: any) => {
      const newArr = [] as any
      const filArrMpp = new Map()
      arr.forEach((v: any) => {
        v.selectStatus = false
        if (!filArrMpp.has(v.year + '-' + v.month)) {
          filArrMpp.set(v.year + '-' + v.month, [v])
        } else {
          filArrMpp.get(v.year + '-' + v.month).push(v)
        }
      })
      filArrMpp.forEach((v: any) => {
        newArr.push(v)
      })
      return newArr
    }
    // 处理数据
    const handleData = (data: any) => {
      // 将data 中相同的year + month 变成一个 数组
      const arr = filArr(data)
      const newArr = arr.map((item: any) => {
        return {
          title: item[0].year + '年' + item[0].month + '月',
          list: item
        }
      })
      state.tableData = [...newArr, ...state.tableData]
      state.mainLoading = false
    }
    // 请求列表
    const queryStatementsPageList = async (statementsParams: any) => {
      const res = await getStatementsPageList(statementsParams, {
        notifyOnNetworkStatusChange: true
      })
      try {
        const { StatementsPageList } = res.data
        if (StatementsPageList.ok) {
          const arr = _.cloneDeep(StatementsPageList?.data?.rows || [])
          state.email = StatementsPageList?.data?.email || ''
          state.total = StatementsPageList?.data?.totalCount || 0
          console.log(state.total, 'state.total')
          if (state.total > 0) {
            handleData(arr)
          } else {
            handleData([])
          }
          return
        }
        ElMessage({
          message: StatementsPageList.reason.desc,
          type: 'error',
          customClass: 'custom-error'
        })
        state.mainLoading = false
      } catch (error) {
        console.log(error)
        state.mainLoading = false
      }
    }
    queryStatementsPageList(statementsParams.value)
    // 滚动加载
    const load = _.debounce(() => {
      if (state.total < 1) {
        return
      }
      state.page++
      console.log(state.page, 'pageSize')
      statementsParams.value.filter.page = state.page
      console.log(statementsParams.value, 'statementsParams.value')
      queryStatementsPageList(statementsParams.value)
    }, 300)
    // 计算属性 计算选中的个数
    const selectCount = computed(() => {
      let count = 0
      const statementIds = [] as any
      state.tableData.forEach((item: any) => {
        item.list.forEach((listItem: any) => {
          if (listItem.selectStatus) {
            count++
            statementIds.push(listItem.statementId)
          }
        })
      })
      return { count, statementIds }
    })
    // 显示日结月结单
    const showDayMonth = (item: object | any) => {
      if (item.statementType === 'D') {
        return item.day + '/' + item.month + '-' + '日结单'
      }
      if (item.statementType === 'M') {
        return '月结单'
      }
    }
    watch(
      () => state.searchFormData,
      newValue => {
        console.log(newValue, 'newValue')
        if (newValue.dataType === 1) {
          state.searchList = searchList
        } else {
          state.searchList = searchListNoDate
        }
        if (newValue.dataType === 1 && Number(newValue.startDate) === 0) {
          console.log('进来了')
          return
        }
        // 改变搜索条件
        list.value.wrap.scrollTop = 0
        state.page = 1
        state.tableData = []
        // 请求的数据
        state.mainLoading = true
        if (newValue.dataType === 1) {
          statementsParams.value = {
            filter: {
              exchangeType: 'ALL',
              statementType: newValue.statementType,
              fundAccount: state.fundAccount,
              page: state.page,
              pageSize: state.pageSize,
              startDate: Number(newValue.startDate),
              endDate: Number(newValue.endDate)
            }
          }
        } else {
          statementsParams.value = {
            filter: {
              exchangeType: 'ALL',
              statementType: newValue.statementType,
              fundAccount: state.fundAccount,
              page: state.page,
              pageSize: state.pageSize
            }
          }
        }
        console.log(statementsParams.value, 'statementsParams.value')
        // 刷新数据
        queryStatementsPageList(statementsParams.value)
      },
      { deep: true }
    )
    // 月度全选
    const monthAll = (item: any, status: boolean) => {
      item.selectStatus = status
      item.list.forEach((listItem: any) => {
        listItem.selectStatus = status
      })
    }
    // 单个日期选中
    const dayAll = (listItem: any, item: any, status: boolean) => {
      // 已经全选
      if (item.selectStatus) {
        item.selectStatus = false
        listItem.selectStatus = false
      } else {
        // 判断是否全选
        listItem.selectStatus = status
        let isAll = true
        item.list.forEach((listItem: any) => {
          if (!listItem.selectStatus) {
            isAll = false
          }
        })
        item.selectStatus = isAll
      }
    }
    // 发送到我邮箱
    const sendEmail = () => {
      if (state.sendLoading) return
      // 获取选中的数据
      if (selectCount.value.count < 1) return
      console.log(
        selectCount.value.statementIds,
        'selectCount.value.statementIds'
      )
      const obj = {
        email: state.email,
        statementIdList: selectCount.value.statementIds
      }
      console.log(obj, 'obj')
      ElMessageBox.confirm(
        `<p style="text-align:center;">确定结单发送至<span style="color:#3a96ff">${state.email}</span>邮箱吗?<p>`,
        '发送结单',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          center: true,
          customClass: 'message-info-change',
          showClose: false,
          dangerouslyUseHTMLString: true,
          closeOnClickModal: false
        }
      )
        .then(async () => {
          state.sendLoading = true
          const res = await sendToEmail({ params: obj }, {})
          const { sendStatement } = res?.data
          if (sendStatement.ok) {
            state.sendLoading = false
            ElMessage({
              message: sendStatement.data.attention,
              type: 'success',
              customClass: 'custom-success'
            })
            // 刷新列表
            return
          }
          state.sendLoading = false
          ElMessage({
            message: sendStatement.reason.desc,
            type: 'error',
            customClass: 'custom-error'
          })
        })
        .catch(() => ({}))
    }
    // 提示信息
    const sendPdfInfo = () => {
      state.pwdDialog = true
    }
    return {
      ...toRefs(state),
      monthAll,
      sendEmail,
      dayAll,
      showDayMonth,
      sendPdfInfo,
      load,
      list,
      selectCount
    }
  }
})
